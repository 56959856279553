import { Button, useDisclosure } from '@chakra-ui/react'
import {
  Center,
  Flex,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem as ChakraMenuItem,
  Image,
  IconButton
} from 'components/atoms'
import { useProfile } from 'contexts/profileContext'
import { CustomerWallet, WalletCatalogForCustomer } from 'models/CustomerWallet'
import { PropsWithChildren, useState } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IconsName } from 'types'
import { capitalizeSentence, limitString } from 'utils/stringUtils'

import { getFinalWalletStatus, WalletDetails } from './walletDetails'
import { StatusEnum, WalletStatus } from './walletStatus'

export const WalletCard = (customerWallet: CustomerWallet) => {
  const {
    name,
    allowTransfer,
    allowWithdraw,
    status,
    lastModified,
    wallet,
    dailyLimitWithdrawTx,
    dailyLimitWithdrawValue,
    monthlyLimitWithdrawTx,
    monthlyLimitWithdrawValue,
    withdrawFee
  } = customerWallet

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  return (
    <>
      <Wrapper>
        <LeftSide />
        <RightSide
          wallet={wallet}
          onOpenDetails={onOpenDetails}
          customerWallet={customerWallet}
        />
      </Wrapper>

      <WalletDetails
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        allowTransfer={allowTransfer}
        allowWithdraw={allowWithdraw}
        lastModified={lastModified}
        name={name}
        status={status}
        wallet={wallet}
        dailyLimitWithdrawTx={dailyLimitWithdrawTx}
        dailyLimitWithdrawValue={dailyLimitWithdrawValue}
        monthlyLimitWithdrawTx={monthlyLimitWithdrawTx}
        monthlyLimitWithdrawValue={monthlyLimitWithdrawValue}
        withdrawFee={withdrawFee}
      />
    </>
  )
}

const Wrapper = ({ children }: PropsWithChildren) => (
  <Flex
    w="345px"
    h="190px"
    border="1px solid"
    borderRadius="4px"
    borderColor="standard.white"
    boxShadow="1px 1px 0px 0px #221C4633"
  >
    {children}
  </Flex>
)

const LeftSide = () => (
  <Flex
    w="39px"
    h="100%"
    bg="#D7FCEA"
    backgroundImage="url('/img/pluxee/cardBg.png')"
    backgroundRepeat="no-repeat"
    backgroundSize="auto"
    backgroundPosition="right"
  />
)

const RightSide = ({
  onOpenDetails,
  wallet,
  customerWallet
}: {
  onOpenDetails: () => void
  customerWallet: CustomerWallet
  wallet: WalletCatalogForCustomer
}) => {
  const { isSupervisorB2b } = useProfile()
  const { status, name, allowTransfer, allowWithdraw } = customerWallet
  const navigate = useNavigate()

  const walletName = capitalizeSentence(limitString(name, 18))

  return (
    <Flex
      bg="pluxee.primarys.deepBlue"
      flex="1"
      h="100%"
      p="21.5px 12px"
      flexDir="column"
      justifyContent="center"
      gap={customerWallet.customValues ? '12px' : '24px'}
    >
      {/* Info Area */}
      <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
        <Flex flex="5" gap="8px" alignItems="start">
          {/* Info Area */}
          <Center
            w="32px"
            h="32px"
            borderRadius="4px"
            bg={renderWalletBg(walletName as WalletIcon)}
          >
            {renderWalletIcon(walletName as WalletIcon)}
          </Center>
          <Flex flexDir="column" color="pluxee.text.primary" gap="1px">
            <Text
              fontWeight="600"
              fontSize="xs"
              lineHeight="24px"
              letterSpacing="-0.8px"
            >
              {walletName}
            </Text>
            <WalletStatus
              status={
                getFinalWalletStatus({
                  customerWalletStatus: status,
                  walletCatalogStatus: wallet.status
                }) as StatusEnum
              }
            />
          </Flex>
        </Flex>

        <Flex flex="1">
          <PluxeeMenu
            items={[
              { title: 'Ver detalhes dos limites', onClick: onOpenDetails },
              {
                title: 'Customizar carteira',
                onClick: () =>
                  navigate('/customized-wallet-update', {
                    state: { customerWallet, walletCatalog: wallet }
                  }),
                isDisabled: !allowWithdraw || isSupervisorB2b,
                bgColor: '#DEF3FB',
                iconName: 'settings'
              }
            ]}
          />
        </Flex>
      </Flex>

      {customerWallet.customValues && (
        <Flex
          p="4px 8px"
          bg="#E3E2FA"
          color="#221C46"
          gap="4px"
          alignItems="center"
          maxW="fit-content"
          position="relative"
          _before={{
            content: "''",
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '8px',
            height: '8px',
            border: '4px solid transparent',
            borderBottomColor: '#FAF8FF',
            borderRightColor: '#FAF8FF',
            transform: 'rotate(0deg)'
          }}
        >
          <Icon name="settings" variant="outlined" size="xs" />
          <Text
            fontSize="14px"
            fontWeight="500"
            whiteSpace="nowrap"
            letterSpacing="-0.8px"
          >
            Carteira customizada
          </Text>
        </Flex>
      )}

      {/* Withdraw Info Area */}
      <Flex
        flexDir="column"
        bg="standard.white"
        w="100%"
        h="52px"
        p="8px"
        gap="4px"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          color="pluxee.text.secondary"
          lineHeight="16.2px"
          letterSpacing="-0.8px"
        >
          <Text fontWeight="500" fontSize="xxxs">
            Permite transferência?
          </Text>
          <Text fontWeight="600" fontSize="xxxs">
            {allowTransfer ? 'Sim' : 'Não'}
          </Text>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="space-between"
          color="pluxee.text.secondary"
          lineHeight="16.2px"
        >
          <Text fontWeight="500" fontSize="xxxs">
            Permite saque?
          </Text>
          <Text fontWeight="600" fontSize="xxxs">
            {allowWithdraw ? 'Sim' : 'Não'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export type WalletIcon =
  | 'Alimentação'
  | 'Refeição'
  | 'Gift'
  | 'Mobilidade'
  | 'Educação'
  | 'Saúde & Bem-estar'
  | 'Home Office'
  | 'Educacao e Cultura'

export const renderWalletIcon = (walletName: WalletIcon) => {
  const data = {
    Alimentação: (
      <Image src="/icons/pluxee/alimentacao.svg" alt="Wallet Icon" />
    ),
    Refeição: <Image src="/icons/pluxee/refeicao.svg" alt="Wallet Icon" />,
    Gift: <Image src="/icons/pluxee/gift.svg" alt="Wallet Icon" />,
    Mobilidade: <Image src="/icons/pluxee/mobilidade.svg" alt="Wallet Icon" />,
    'Saúde & Bem-estar': (
      <Image src="/icons/pluxee/saudebemestar.svg" alt="Wallet Icon" />
    ),
    'Home Office': (
      <Image src="/icons/pluxee/homeoffice.svg" alt="Wallet Icon" />
    ),
    Educação: <Image src="/icons/pluxee/educacao.svg" alt="Wallet Icon" />,
    'Educacao e Cultura': (
      <Image src="/icons/pluxee/educacao.svg" alt="Wallet Icon" />
    ),
    Default: <Image src="/icons/pluxee/default.svg" alt="Wallet Icon" />
  }

  return data[walletName] ?? data['Default']
}

export const renderWalletBg = (walletName: WalletIcon) => {
  const data = {
    Alimentação: '#C7FCCA',
    Refeição: '#C7FCCA',
    Gift: '#FDEEC1',
    Mobilidade: '#FCC1BE',
    'Saúde & Bem-estar': '#FDEEC1',
    'Home Office': '#FDEEC1',
    Educação: '#FDEEC1',
    'Educacao e Cultura': '#FDEEC1',
    Default: '#DEF3FB'
  }

  return data[walletName] ?? data['Default']
}

const PluxeeMenu = ({
  items
}: {
  items: {
    title: string
    isDisabled?: boolean
    onClick: () => void
    bgColor?: string
    iconName?: IconsName
  }[]
}) => {
  const [boxShadow, setBoxShadow] = useState('')

  return (
    <Menu isLazy placement="bottom-end">
      <MenuButton
        as={IconButton}
        color="pluxee.text.link"
        borderRadius="none"
        aria-label="Menu Options"
        variant="unstyled"
        background="transparent"
        onClick={event => {
          setBoxShadow('4px 4px 0px 0px #A9A7B6')
          event.stopPropagation()
        }}
        _hover={{ color: 'pluxee.cta.secondaryPressed' }}
        _active={{ color: 'pluxee.cta.secondaryPressed' }}
        _focusVisible={{
          border: '3px solid',
          borderColor: 'pluxee.text.link',
          color: 'pluxee.cta.secondaryPressed'
        }}
        icon={
          <Icon name="more_vert" variant="outlined" size="xs" color="inherit" />
        }
      />

      <MenuList
        p="0"
        borderRadius="0"
        border="none"
        boxShadow={boxShadow}
        _hover={{
          background: 'transparent',
          boxShadow: 'none'
        }}
      >
        {items.map(({ title, isDisabled, onClick, bgColor, iconName }) => (
          <MenuItem
            key={`Menu item - ${title}`}
            title={title}
            isDisabled={isDisabled!}
            onClick={onClick}
            bgColor={bgColor}
            iconName={iconName}
          />
        ))}
      </MenuList>
    </Menu>
  )
}

const MenuItem = ({
  title,
  isDisabled,
  onClick,
  bgColor = 'standard.white',
  iconName = 'search'
}: {
  title: string
  isDisabled: boolean
  onClick: () => void
  bgColor?: string
  iconName?: IconsName
}) => {
  const defaultStyles = {
    boxShadow: '4px 4px 0px 0px #A9A7B6 !important',
    background: '#DEF3FB',
    color: 'pluxee.text.primary'
  }

  return (
    <ChakraMenuItem
      display={isDisabled ? 'none' : 'flex'}
      as={Button}
      borderRadius="none"
      onClick={onClick}
      h="48px"
      background={bgColor}
      border="1px solid"
      borderColor="pluxee.border.secondary"
      fontWeight="600"
      fontSize="xxs"
      color="pluxee.text.primary"
      boxShadow="4px 4px 0px 0px #A9A7B6"
      outline="none"
      gap="8px"
      paddingInlineStart="8px"
      paddingInlineEnd="30px"
      letterSpacing="-0.8px"
      justifyContent="flex-start"
      _hover={{ ...defaultStyles }}
      _active={{
        ...defaultStyles,
        color: 'standard.white',
        background: 'pluxee.cta.secondaryPressed'
      }}
      _focusVisible={{ ...defaultStyles, border: '3px solid #1B51DC' }}
    >
      <Icon name={iconName} variant="outlined" />
      {title}
    </ChakraMenuItem>
  )
}
