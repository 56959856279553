import {
  useMutateGetTransactionByUUID,
  useQueryGetTransactionByUUID
} from 'hooks/queries/transactionsQueries'
import { TransanctionDetailsByUUID } from 'models/response/TransactionDetailsByUUID'
import { WalletTransaction } from 'models/WalletTransaction'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { objectIsEmpty } from 'utils/objectUtils'

export type ContextType = {
  transaction?: TransanctionDetailsByUUID

  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  isEmptyTransaction: boolean

  setTransactionUUID: (value: string) => void
  fetchTransactionByUUID: ({
    uuid
  }: {
    uuid: string
  }) => Promise<WalletTransaction>
}

export const Context = createContext({} as ContextType)

type TransactionsProviderProps = {
  children: ReactNode
}

export const TransactionsProvider = ({
  children
}: TransactionsProviderProps) => {
  const [transaction, setTransaction] = useState<TransanctionDetailsByUUID>()
  const [transactionUUID, setTransactionUUID] = useState('')
  const isEmptyTransaction = objectIsEmpty(transaction)

  const queryTransaction = useQueryGetTransactionByUUID(transactionUUID)
  const {
    isLoading,
    isError,
    isSuccess,
    data: transactionData
  } = queryTransaction

  const mutateTransaction = useMutateGetTransactionByUUID()

  const fetchTransactionByUUID = async ({ uuid }: { uuid: string }) => {
    return await mutateTransaction.mutateAsync(uuid)
  }

  useEffect(() => {
    if (transactionUUID && transactionData) setTransaction(transactionData)
  }, [transactionUUID, transactionData])

  useEffect(() => {
    if (isError) setTransaction(undefined)
  }, [isError])

  return (
    <Context.Provider
      value={{
        transaction,
        setTransactionUUID,
        isLoading,
        isSuccess,
        isError,
        isEmptyTransaction,
        fetchTransactionByUUID
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useTransactions = () => useContext(Context)
