import { useMutation, useQuery } from 'react-query'
import { ConsumerService } from 'services/consumerService'

export function useQueryGetTransactionByUUID(transactionUUID?: string) {
  return useQuery(
    ['getByTransactionUUID', transactionUUID],
    () => getTransactionsDetailsByUUID(transactionUUID),
    { retry: 0 }
  )
}

export function useMutateGetTransactionByUUID() {
  return useMutation(
    (transactionUUID?: string) => getByTransactionUUID(transactionUUID),
    { retry: 0 }
  )
}

const getByTransactionUUID = async (transactionUUID?: string) => {
  if (!transactionUUID) return

  const response = await ConsumerService.getByTransactionUUID(transactionUUID)
  return response.data
}

const getTransactionsDetailsByUUID = async (transactionUUID?: string) => {
  if (!transactionUUID) return

  const response = await ConsumerService.getTransactionsDetailsByUUID(
    transactionUUID
  )
  return response.data
}
