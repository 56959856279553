import { Flex, useDisclosure } from '@chakra-ui/react'
import { Center, Text } from 'components/atoms'
import { NoDataFound } from 'components/organisms'
import { ResponseCode, Table, TransactionStatus } from 'components/ui'
import { CellProps } from 'components/ui/Table'
import { useAccountWallet } from 'contexts/accountWalletContext'
import { useProfile } from 'contexts/profileContext'
import { useTransactions } from 'contexts/transactionsProvider'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { translate } from 'internationalization'
import { TransanctionDetailsByUUID } from 'models/response/TransactionDetailsByUUID'
import { type WalletTransaction } from 'models/WalletTransaction'
import React, { useState } from 'react'
import ContentLoader from 'react-content-loader'
import { TransactionDetailsModal } from 'templates/consumerDetails/transactions/modals/transactionDetails'
import { CreditReversal } from 'templates/consumerDetails/transactions/operations/creditReversal'
import { ProvisionalCredit } from 'templates/consumerDetails/transactions/operations/provisionalCredit'
import { ReverseTransaction } from 'templates/consumerDetails/transactions/operations/reverseTransaction'
import { AppetizerOptions } from 'templates/consumerDetails/transactions/options/appetizerOptions'
import { ExitsOptions } from 'templates/consumerDetails/transactions/options/exitsOptions'
import { TransactionOptions } from 'templates/consumerDetails/transactions/options/transactionOptions'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence, limitString } from 'utils/stringUtils'
import * as TransactionUtils from 'utils/transactionsUtils'

import { TransactionAccordion } from './transactionAccordion'

export const TransactionTable = () => {
  const {
    isAttendant,
    isAttendant3c,
    isAttendantB2M,
    isSupervisorB2b,
    isBackoffice4c,
    isFraudAndPrevention,
    isManageTransactions,
    isOperation3c,
    isPayments
  } = useProfile()

  const { setWalletId, setAccountId } = useWalletTransaction()
  const { transaction, isLoading, isError, isEmptyTransaction } =
    useTransactions()

  const { refreshWalletList } = useAccountWallet()

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails
  } = useDisclosure()

  const {
    isOpen: isOpenOutboundTransaction,
    onOpen: onOpenDetailsOutboundTransaction,
    onClose: onCloseOutboundTransaction
  } = useDisclosure()

  const {
    isOpen: isOpenCreditInTrust,
    onOpen: onOpenCreditInTrust,
    onClose: onCloseCreditInTrust
  } = useDisclosure()

  const {
    isOpen: isOpenCreditReversal,
    onOpen: onOpenCreditReversal,
    onClose: onCloseCreditReversal
  } = useDisclosure()

  const {
    isOpen: isOpenReverseTransaction,
    onOpen: onOpenReverseTransaction,
    onClose: onCloseReverseTransaction
  } = useDisclosure()

  const [selectedTransaction, setSelectedTransaction] =
    useState<WalletTransaction | null>(null)

  const columns: CellProps[] = [
    {
      title: 'Data e hora',
      width: '100px',
      render: ({
        transaction: data
      }: Record<keyof TransanctionDetailsByUUID, any>) => {
        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color="pluxee.text.secondary"
            letterSpacing="-0.7px"
          >
            {formatToDateAndTime(data.transactionDate, true)}
          </Text>
        )
      }
    },
    {
      title: 'ID transação',
      render: ({
        transaction: data
      }: Record<keyof TransanctionDetailsByUUID, any>) => {
        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color="pluxee.text.secondary"
            letterSpacing="-0.7px"
          >
            {TransactionUtils.formatTransactionId(data.transactionUUID)}
          </Text>
        )
      }
    },
    {
      title: 'Descrição',
      width: '250px',
      render: ({
        transaction: data
      }: Record<keyof TransanctionDetailsByUUID, any>) => {
        const capitalizedDescription = capitalizeSentence(data.description)

        return (
          <Text
            fontSize="14px"
            fontWeight="500"
            color="pluxee.text.secondary"
            letterSpacing="-0.7px"
          >
            {limitString(capitalizedDescription, 50)}
          </Text>
        )
      }
    },
    {
      title: translate('commons.value'),
      render: ({
        transaction: data
      }: Record<keyof TransanctionDetailsByUUID, any>) => {
        return (
          <Text
            fontSize="14px"
            fontWeight="700"
            color="#0F2365"
            letterSpacing="-0.7px"
          >
            {formatToBrl(Number(data.value))}
          </Text>
        )
      }
    },
    {
      title: translate('commons.status'),
      render: ({
        transaction: data
      }: Record<keyof TransanctionDetailsByUUID, any>) => {
        console.log('data', data.status)
        return <TransactionStatus letterSpacing="-0.7px" status={data.status} />
      }
    },
    {
      title: ' ',
      render: ({
        transaction: data
      }: Record<keyof TransanctionDetailsByUUID, any>) => {
        const formattedResponseCode = data.responseCode
          ? TransactionUtils.formatResponseCode({ code: data.responseCode })
          : '-'

        return (
          <ResponseCode
            text={formattedResponseCode}
            maxLenght={25}
            letterSpacing="-0.7px"
          />
        )
      }
    },
    {
      title: '',
      width: '10px',
      render: ({
        transaction: data
      }: Record<keyof TransanctionDetailsByUUID, any>) => {
        transaction && setAccountId(transaction.account?.id)

        const isShowCreditReversal =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E' &&
          data.type === 'CTR'

        const isShowCreditInTrust =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isBackoffice4c &&
          !isManageTransactions &&
          !isOperation3c &&
          !isPayments &&
          data.status === 'E'

        const isShowProvisionalCreditForExit =
          (isShowCreditInTrust && data.type === 'WTX') ||
          (isShowCreditInTrust && data.type === 'WFT')

        const isShowReverseTransaction =
          !isAttendant &&
          !isAttendant3c &&
          !isAttendantB2M &&
          !isSupervisorB2b &&
          !isFraudAndPrevention &&
          !isOperation3c &&
          !isManageTransactions &&
          !isPayments &&
          data.status === 'E'

        const isShowReverseTransactionForExit =
          (isShowReverseTransaction && data.type === 'WTX') ||
          (isShowReverseTransaction && data.type === 'WFT')

        const isExit = data.type !== 'PTX' && data.operationType === '-'
        const isAppetizer = data.operationType === '+'

        if (isExit) {
          return (
            <ExitsOptions
              data={data}
              setWalletId={() => setWalletId(data?.walletId)}
              onOpenDetails={onOpenDetailsOutboundTransaction}
              setSelectedTransaction={setSelectedTransaction}
              onOpenProvisionalCredit={onOpenCreditInTrust}
              isShowProvisionalCredit={isShowProvisionalCreditForExit}
              isShowReverseTransaction={isShowReverseTransactionForExit}
              onOpenReverseTransaction={onOpenReverseTransaction}
            />
          )
        }

        if (isAppetizer) {
          return (
            <AppetizerOptions
              data={data}
              setWalletId={() => setWalletId(data?.walletId)}
              isShowCreditReversal={isShowCreditReversal}
              onOpenDetails={onOpenDetailsOutboundTransaction}
              onOpenCreditReversal={onOpenCreditReversal}
              setSelectedTransaction={setSelectedTransaction}
            />
          )
        }

        return (
          <TransactionOptions
            data={data}
            setWalletId={() => setWalletId(data?.walletId)}
            isShowProvisionalCredit={isShowCreditInTrust}
            isShowReverseTransaction={isShowReverseTransaction}
            onOpenProvisionalCredit={onOpenCreditInTrust}
            onOpenDetails={onOpenDetails}
            onOpenReverseTransaction={onOpenReverseTransaction}
            setSelectedTransaction={setSelectedTransaction}
          />
        )
      }
    }
  ]

  if (isLoading) return <Skeleton />

  const isEmptyTable = (isEmptyTransaction && !isLoading) || isError

  if (isEmptyTable)
    return (
      <Center w="100%">
        <NoDataFound />
      </Center>
    )

  return (
    <>
      <Flex flexDir="column" mt="-25px">
        <Table
          minWidth="100%"
          size="sm"
          textType="initial"
          columns={columns}
          list={[transaction!]}
          isPaginated={false}
          onClickRow={data => {
            const isTransaction = data.type === 'PTX'
            setSelectedTransaction(data.transaction)

            if (isTransaction) onOpenDetails()
            else onOpenDetailsOutboundTransaction()
          }}
        />

        {transaction && <TransactionAccordion {...transaction} />}
      </Flex>

      <TransactionDetailsModal
        isOpen={isOpenDetails}
        onClose={onCloseDetails}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <TransactionDetailsModal
        isOpen={isOpenOutboundTransaction}
        onClose={onCloseOutboundTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        isOutboundTransaction
      />

      <ProvisionalCredit
        isOpen={isOpenCreditInTrust}
        onClose={onCloseCreditInTrust}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />

      <CreditReversal
        isOpen={isOpenCreditReversal}
        onClose={onCloseCreditReversal}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
        onSave={() => {
          refreshWalletList()
        }}
      />

      <ReverseTransaction
        isOpen={isOpenReverseTransaction}
        onClose={onCloseReverseTransaction}
        transaction={selectedTransaction ?? ({} as WalletTransaction)}
      />
    </>
  )
}

const Skeleton = () => (
  <ContentLoader viewBox="0 0 380 200">
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="100" />
  </ContentLoader>
)
