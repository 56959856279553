import {
  Button,
  Center,
  Collapse,
  Flex,
  Image,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Icon } from 'components/atoms'
import { formatCNPJ, formatCPF } from 'internationalization'
import { TransanctionDetailsByUUID } from 'models/response/TransactionDetailsByUUID'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatToBrl } from 'utils/currencyUtils'
import { formatDateToBrazilianFormat } from 'utils/dateUtils'
import { formatConsumerModel } from 'utils/productUtils'
import { capitalizeSentence } from 'utils/stringUtils'

export const TransactionAccordion = ({
  account,
  transaction,
  consumer,
  customer,
  accountWalletDetails
}: TransanctionDetailsByUUID) => {
  const navigate = useNavigate()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })

  const { creationDate, productId, closureDate, id: accountId } = account
  const wallet = accountWalletDetails[0]

  const isUnlimitedDailyTx = wallet.dailyLimitTx >= 999999

  const formattedCreationDate = formatDateToBrazilianFormat(creationDate)

  const formattedClosureDate = closureDate
    ? formatDateToBrazilianFormat(closureDate)
    : '-'

  const formattedConsumerModel = customer.products
    ?.map(item => {
      const { formattedConsumerModel } = formatConsumerModel({
        isPatCustomer: item.patCustomer
      })

      return formattedConsumerModel
    })
    .join(' | ')

  const goToConsumerPage = () => {
    window.localStorage.setItem('cachedCPF', consumer.document)
    navigate(`/consumer`, {
      state: { isFromTransactionPage: true }
    })
  }

  return (
    <Flex
      flexDir="column"
      borderRadius="none"
      borderTop="2px solid #1B51DC"
      borderBottom="4px solid #D1CFD7"
      gap="1px"
      justifyContent="center"
      alignItems="center"
    >
      {/* Header */}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        p="12px 16px"
        borderBottom="1px solid #D1CFD7"
        bg="#FAF8FF"
      >
        <Flex gap="98px" alignItems="center">
          <Flex alignItems="center" justifyContent="center" gap="8px">
            <Center bg="#DEF3FB" w="32px" h="32px" borderRadius="6px">
              <Image
                src="/img/pluxee/profile.svg"
                alt="Profile Icon"
                w="24px"
                h="24px"
              />
            </Center>

            <Text
              color="#221C46"
              fontSize="14px"
              fontWeight="500"
              letterSpacing="-0.7px"
            >
              Conta #{accountId}
            </Text>
          </Flex>

          <Flex alignItems="center" justifyContent="center" gap="8px">
            <Center bg="#C7FCCA" w="32px" h="32px" borderRadius="6px">
              <Image
                src="/img/pluxee/food.svg"
                alt="Food Icon"
                w="24px"
                h="24px"
              />
            </Center>
            <Text
              color="#221C46"
              fontSize="14px"
              fontWeight="500"
              letterSpacing="-0.7px"
            >
              {capitalizeSentence(transaction.walletName!)}
            </Text>
          </Flex>
        </Flex>

        <Flex gap="32px" alignItems="center">
          <Flex
            onClick={goToConsumerPage}
            as={Button}
            bg="transparent"
            maxHeight="32px"
            p="8px 16px"
            w="126px"
            borderRadius="none"
            border="3px solid #1B51DC"
            color="#1B51DC"
            _hover={{
              boxShadow: '4px 4px 0px 0px rgba(34, 28, 70, 0.20) !important',
              bg: '#DEF3FB',
              color: '#1B51DC'
            }}
            _active={{
              boxShadow: '2px 2px 0px 0px rgba(34, 28, 70, 0.20) !important',
              bg: '#0F266D',
              color: 'white',
              borderColor: '#0F266D'
            }}
            _focusVisible={{
              outline: '3px solid #1B51DC',
              bg: '#DEF3FB'
            }}
          >
            <Text
              fontSize="12px"
              fontWeight="700"
              letterSpacing="-0.6px"
              lineHeight="0"
            >
              Ver Colaborador
            </Text>
          </Flex>

          <Flex
            onClick={onToggle}
            as={Button}
            cursor="pointer"
            w="40px"
            h="40px"
            bg="transparent"
            borderRadius="none"
            outline="none"
            border="none"
            color="#1B51DC"
            _focusVisible={{
              border: '3px solid #1B51DC',
              color: '#0F266D'
            }}
            _hover={{
              bg: 'transparent'
            }}
          >
            <Icon
              color="inherit"
              name={isOpen ? 'expand_less' : 'expand_more'}
              size="lg"
              _hover={{
                bg: 'transparent',
                color: '#0F266D'
              }}
            />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ width: '100%' }}>
        <Flex bg="#FAF8FF" w="100%" h="100%" gap="32px" justifyContent="center">
          {/* LeftSide */}
          <Flex
            w="100%"
            minW="604px"
            h="100%"
            p="16px"
            gap="16px"
            flexDir="column"
            alignItems="flex-start"
          >
            <Flex alignItems="center" justifyContent="center" gap="8px">
              <Image src="/img/transactions/person2.svg" alt="Profile" />
              <Text
                color="#221C46"
                fontSize="16px"
                fontWeight="700"
                letterSpacing="-0.7px"
              >
                Conta #{accountId}
              </Text>
            </Flex>

            <Flex w="100%" gap="74px">
              <Flex flexDir="column" gap="8px">
                <Info title="Nome:" value={consumer.name} />
                <Info title="CPF:" value={formatCPF(consumer.document)} />
                <Info title="Criado em:" value={formattedCreationDate} />
                <Info title="Inativado em:" value={formattedClosureDate} />
              </Flex>

              <Flex flexDir="column" gap="8px">
                <Info customWidth="80px" title="Produto:" value={productId} />
                <Info
                  customWidth="80px"
                  title="Cliente:"
                  value={customer.name}
                />
                <Info
                  customWidth="80px"
                  title="CNPJ:"
                  value={formatCNPJ(customer.document)}
                />
                <Info
                  customWidth="80px"
                  title="Modelo:"
                  value={formattedConsumerModel ?? '-'}
                />
              </Flex>
            </Flex>
          </Flex>

          {/* LeftSide */}
          <Flex
            w="100%"
            h="100%"
            minW="379px"
            p="16px"
            gap="12px"
            flexDir="column"
            borderRadius="12px"
            alignItems="flex-start"
          >
            <Flex alignItems="center" gap="8px" mb="4px">
              <Image src="/img/transactions/food.svg" alt="Wallet" />
              <Text
                color="#221C46"
                fontSize="16px"
                fontWeight="700"
                letterSpacing="-0.8px"
              >
                {capitalizeSentence(transaction.walletName!)}
              </Text>
            </Flex>

            <Flex flexDir="column">
              <Text
                fontWeight="700"
                fontSize="12px"
                color="#221C46"
                letterSpacing="-0.6px"
              >
                Saldo total
              </Text>
              <Text
                fontSize="24px"
                fontWeight="700"
                color="#221C46"
                letterSpacing="-1.2px"
              >
                {formatToBrl(wallet.balance ?? 0)}
              </Text>
            </Flex>

            <Flex flexDir="column" w="100%" p="8px" bg="white">
              <Flex
                justifyContent="space-between"
                fontSize="12px"
                color="#463F5F"
                fontWeight="500"
              >
                <Text>Limite diário disponível:</Text>
                <Text fontWeight="600">
                  {wallet.dailyLimitValueRemaining &&
                    formatToBrl(wallet.dailyLimitValueRemaining ?? 0)}
                </Text>
              </Flex>

              <Flex
                justifyContent="space-between"
                fontSize="12px"
                color="#463F5F"
                fontWeight="500"
              >
                <Text>Limite de transações diárias:</Text>
                <Text fontWeight="600">
                  {isUnlimitedDailyTx && 'Ilimitado'}
                  {!isUnlimitedDailyTx &&
                    `${wallet.dailyLimitTxRemaining} de ${wallet.dailyLimitTx}`}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Collapse>
    </Flex>
  )
}

const Info = ({
  title,
  value,
  customWidth = '120px'
}: {
  title: string
  value: string | number
  customWidth?: string
}) => (
  <Flex fontSize="14px" color="#221C46">
    <Text fontWeight="700" minW={customWidth} letterSpacing="-0.7px">
      {title}
    </Text>
    <Text fontWeight="500" letterSpacing="-0.7px">
      {value}
    </Text>
  </Flex>
)
