import { Spinner, Tag } from '@chakra-ui/react'
import { Divider, Flex, Text, VStack } from 'components/atoms'
import {
  CloseButton,
  Modal,
  TransactionStatus,
  TransactionHistory
} from 'components/ui'
import { useTransactions } from 'contexts/transactionsProvider'
import { useWalletTransaction } from 'contexts/walletTransactionContext'
import { type WalletTransaction } from 'models/WalletTransaction'
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { formatToBrl } from 'utils/currencyUtils'
import { formatToDateAndTime } from 'utils/dateUtils'
import { capitalizeSentence } from 'utils/stringUtils'
import {
  formatCreditCardNumber,
  formatMccDescription,
  formatResponseCode,
  formatTransactionLabel,
  formatTransactionType,
  TransactionLabel
} from 'utils/transactionsUtils'

const RefundHistory = lazy(
  /* webpackChunkName: 'RefundHistory' */ () =>
    import('./components/RefundHistory')
)

const HasHistoryWarning = lazy(
  /* webpackChunkName: 'HistoryWarning' */ () =>
    import('./components/HasHistoryWarning')
)

type TransactionDetailsModalProps = {
  isOpen: boolean
  onClose: () => void
  transaction?: WalletTransaction
  isOutboundTransaction?: boolean
  originalNSU?: string
}

export const TransactionDetailsModal = ({
  isOpen,
  onClose,
  transaction: currentTransaction,
  originalNSU,
  isOutboundTransaction: initialIsOutboundTransaction = false
}: TransactionDetailsModalProps) => {
  const transactionRef = useRef<WalletTransaction | undefined>(
    currentTransaction
  )

  const [transaction, setTransaction] = useState<WalletTransaction | undefined>(
    currentTransaction
  )
  const [isOutboundTransaction, setIsOutboundTransaction] = useState(
    initialIsOutboundTransaction
  )

  const [showWarning, setShowWarning] = useState(false)

  const {
    refundableHistory,
    getRefundableHistory,
    setRefundableHistory,
    setOriginalTransaction,
    setOriginalTransactionNSU,
    setOriginalTransactionUUID,
    originalTransactionNSU
  } = useWalletTransaction()

  const { fetchTransactionByUUID } = useTransactions()

  const tagTitle = transaction?.voucherTransaction ? 'Voucher' : 'Crédito'
  const formattedCardType = transaction?.isVirtualCard
    ? 'Cartão virtual'
    : 'Cartão físico'
  const formattedCardNumber = transaction?.cardNumber
    ? formatCreditCardNumber(transaction.cardNumber)
    : '-'

  const formattedWithdrawFeeDesc =
    isOutboundTransaction && transaction?.type === 'WFT'
      ? transaction?.description
      : capitalizeSentence(transaction?.description ?? '')

  const formattedNSU = originalTransactionNSU || originalNSU || transaction?.nsu

  const hasRefundHistory =
    (refundableHistory && refundableHistory?.refundHistory?.length > 0) ||
    refundableHistory?.hasProvisionalCreditHistory ||
    showWarning

  const hideRefundHistory =
    !transaction?.type || !['PTX'].includes(transaction.type!)

  const handleClickPTXTransaction = useCallback(
    async (clickedTransaction?: WalletTransaction) => {
      const txn = clickedTransaction || transaction

      if (!txn) return

      const isRefund = ['CTR'].includes(txn.type!)
      const isProvisionalTransaction = ['PRC'].includes(txn.type!)
      const isReverseProvisional = ['PCR'].includes(txn.type!)

      setIsOutboundTransaction(['WFT', 'CTR', 'PRC', 'PCR'].includes(txn.type!))

      if (clickedTransaction) {
        setTransaction(clickedTransaction)

        if (isProvisionalTransaction || isRefund) {
          setRefundableHistory(undefined)
          getRefundableHistory({
            transactionUUID: clickedTransaction.transactionUUID!
          })

          if (['CTR', 'PRC', 'PCR'].includes(txn.type!)) {
            setShowWarning(true)
          } else {
            setShowWarning(false)
          }

          fetchTransactionByUUID({ uuid: clickedTransaction.originUUID! }).then(
            response => {
              setOriginalTransaction(response)
              setOriginalTransactionNSU(response.nsu)
              setOriginalTransactionUUID(response.transactionUUID)
            }
          )
        }

        return
      }

      const fetchTransactionData = async () => {
        getRefundableHistory({ transactionUUID: txn.originUUID! })
        const response = await fetchTransactionByUUID({ uuid: txn.originUUID! })
        setTransaction(response)

        if (response.type === 'PTX') {
          setOriginalTransaction(undefined)
          setOriginalTransactionNSU(undefined)
          setOriginalTransactionUUID(undefined)
        }

        setIsOutboundTransaction(
          ['WFT', 'CTR', 'PRC', 'PCR'].includes(response.type!)
        )

        if (!['CTR', 'PRC', 'PCR'].includes(response.type!)) {
          setShowWarning(false)
        }
      }

      if (isRefund || isProvisionalTransaction || isReverseProvisional) {
        await fetchTransactionData()
      }
    },
    [
      transaction,
      fetchTransactionByUUID,
      getRefundableHistory,
      setRefundableHistory,
      setOriginalTransaction,
      setOriginalTransactionNSU,
      setOriginalTransactionUUID
    ]
  )

  const onCloseModal = () => {
    setShowWarning(false)
    setOriginalTransaction(undefined)
    setOriginalTransactionNSU(undefined)
    setOriginalTransactionUUID(undefined)
    onClose()
  }

  const modalFooter = <CloseButton onClick={onCloseModal} />

  useEffect(() => {
    if (currentTransaction) {
      transactionRef.current = currentTransaction
      setTransaction(currentTransaction)
      setIsOutboundTransaction(
        ['WFT', 'CTR', 'PRC', 'PCR'].includes(currentTransaction.type!)
      )

      if (!['CTR', 'PRC', 'PCR'].includes(currentTransaction.type!)) {
        setShowWarning(false)
      }
    }
  }, [currentTransaction, isOpen])

  if (!transaction) {
    return (
      <Modal
        size="4xl"
        decreaseMarginTop
        heading="Visão detalhada"
        isOpen={isOpen}
        footer={modalFooter}
        onClose={onCloseModal}
        onCloseComplete={onCloseModal}
        closeOnOverlayClick={false}
      >
        <Spinner />
      </Modal>
    )
  }

  return (
    <>
      <Modal
        size={transaction.type === 'WTX' ? '6xl' : '4xl'}
        decreaseMarginTop
        heading="Visão detalhada"
        isOpen={isOpen}
        footer={modalFooter}
        onClose={onCloseModal}
        onCloseComplete={onCloseModal}
        closeOnOverlayClick={false}
      >
        <VStack spacing="xxs" letterSpacing="-0.8px">
          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <Flex flexDir="column" gap="8px">
              <Text fontSize="14px" fontWeight="500">
                ID da transação:
              </Text>
              <Text color="#1B51DC" fontSize="24px" fontWeight="700">
                {transaction.transactionUUID}
              </Text>
              <Flex color="#5A5469" gap="8px" fontSize="14px">
                <Text fontWeight="700">
                  {!originalTransactionNSU
                    ? 'NSU:'
                    : 'NSU da transação original: '}
                </Text>
                <Text fontWeight="500">{formattedNSU ?? '-'}</Text>
              </Flex>
            </Flex>

            {transaction.status && (
              <Flex>
                <TransactionStatus status={transaction.status} />
              </Flex>
            )}
          </Flex>

          {hasRefundHistory && (
            <Suspense fallback={<Spinner />}>
              <HasHistoryWarning
                onClickTransaction={() => handleClickPTXTransaction()}
                message={formatTransactionLabel(
                  transaction.type as TransactionLabel
                )}
                isShowBackToOriginalButton={
                  hasRefundHistory && transaction.type !== 'PTX'
                }
              />
            </Suspense>
          )}

          <Divider />

          <Flex w="100%" gap="24px">
            <Flex flex="1" flexDir="column" gap="24px">
              <Flex
                gap="8px"
                flexDir="column"
                fontWeight="700"
                color="pluxee.text.primary"
                fontSize="xs"
              >
                <Text>Dados da transação</Text>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Valor:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {formatToBrl(Number(transaction.value))}
                  </Text>
                </Flex>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Data:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.transactionDate
                      ? formatToDateAndTime(transaction?.transactionDate)
                      : '-'}
                  </Text>
                </Flex>

                {transaction.description && (
                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Descrição:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {isOutboundTransaction
                        ? formattedWithdrawFeeDesc
                        : capitalizeSentence(transaction.description)}
                    </Text>
                  </Flex>
                )}

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Código de resposta:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.responseCode
                      ? formatResponseCode({ code: transaction.responseCode })
                      : '-'}
                  </Text>
                </Flex>

                {transaction.type && (
                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Tipo de transação:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {formatTransactionType({
                        type: transaction.type
                      })}
                    </Text>
                  </Flex>
                )}

                {isOutboundTransaction && (
                  <>
                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Código da ocorrência:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.occurrenceCode ?? '-'}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Motivo da ocorrência:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.occurrenceReason ?? '-'}
                      </Text>
                    </Flex>
                  </>
                )}

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Modo de entrada:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.entryMode ? transaction.entryMode : '-'}
                  </Text>
                </Flex>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Trilha:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    <Tag
                      bg="#fff"
                      borderRadius="none"
                      border="1px solid #908C99"
                      p="4px 8px"
                      color="#463F5F"
                      fontWeight="500"
                    >
                      {tagTitle}
                    </Tag>
                  </Text>
                </Flex>

                {!isOutboundTransaction && (
                  <Flex>
                    <Text minW="280px" fontSize="xxs">
                      Cartão:
                    </Text>
                    <Text fontWeight="500" fontSize="xxs">
                      {formattedCardType} - {formattedCardNumber}
                    </Text>
                  </Flex>
                )}

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Código de autorização:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.authorizationCode ?? '-'}
                  </Text>
                </Flex>

                <Flex>
                  <Text minW="280px" fontSize="xxs">
                    Número da conta:
                  </Text>
                  <Text fontWeight="500" fontSize="xxs">
                    {transaction.accountId ?? '-'}
                  </Text>
                </Flex>
              </Flex>

              {!hideRefundHistory && (
                <Suspense fallback={<div />}>
                  <RefundHistory
                    refundHistory={refundableHistory?.refundHistory}
                    hasProvisionalCreditHistory={
                      refundableHistory?.hasProvisionalCreditHistory
                    }
                    onClickTransaction={transaction =>
                      handleClickPTXTransaction(transaction)
                    }
                  />
                </Suspense>
              )}

              {!isOutboundTransaction && (
                <>
                  <Divider />

                  <Flex
                    gap="8px"
                    flexDir="column"
                    fontWeight="700"
                    color="pluxee.text.primary"
                    fontSize="xs"
                  >
                    <Text>Estabelecimento</Text>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Estabelecimento:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {capitalizeSentence(transaction.merchantName ?? '-')}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Código do Estabelecimento:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.establishmentCode ?? '-'}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        Código do Adquirente:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.acquirerCode ?? '-'}
                      </Text>
                    </Flex>

                    <Flex>
                      <Text minW="280px" fontSize="xxs">
                        MCC:
                      </Text>
                      <Text fontWeight="500" fontSize="xxs">
                        {transaction.mcc
                          ? formatMccDescription(transaction.mcc)
                          : '-'}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>

          <TransactionHistory
            key={transaction.transactionUUID}
            transaction={transaction}
          />
        </VStack>
      </Modal>
    </>
  )
}
